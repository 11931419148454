import Events from "../internal/lib/events";
import start from "./start";

export default function(
  selector: string,
  details?: PaymentDetailsInit,
  instruments?: PaymentMethodData[],
  options?: PaymentOptions,
) {
  return new Promise((resolve, reject) => {
    const el = document.querySelector(selector);
    if (!el) {
      return reject({
        error: true,
        reasons: [
          { code: "INVALID_CONFIGURATION", message: "Invalid target element" },
        ],
      });
    }

    if (typeof PaymentRequest === "undefined") {
      return reject({
        error: true,
        reasons: [
          { code: "ERROR", message: "PaymentRequest API not available" },
        ],
      });
    }

    // remove the inline display style to reveal
    (el as HTMLElement).style.display = null;

    Events.addHandler(el, "click", (e) => {
      e.preventDefault();
      start(selector, details, instruments, options)
        .then(resolve)
        .catch(reject);
      return false;
    });
  });
}
