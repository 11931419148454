import assetBaseUrl from "../internal/lib/asset-base-url";
import { postMessage } from "../internal/lib/post-message";
import { IframeField } from "../ui/iframe-field";
import {
  defaultDetails as defaultPaymentRequestDetails,
  defaultInstruments as defaultPaymentRequestInstruments,
  defaultOptions as defaultPaymentRequestOptions,
} from "./defaults";

const iframeHolderId = "global-pay-payment-request";

export default function(
  selector: string,
  details?: PaymentDetailsInit,
  instruments?: PaymentMethodData[],
  options?: PaymentOptions,
) {
  return new Promise((resolve, reject) => {
    const target = document.querySelector(selector);

    if (!target) {
      return reject({
        error: true,
        reasons: [
          { code: "INVALID_CONFIGURATION", message: "Invalid target element" },
        ],
      });
    }

    if (typeof PaymentRequest === "undefined") {
      return reject({
        error: true,
        reasons: [
          { code: "ERROR", message: "PaymentRequest API not available" },
        ],
      });
    }

    const holder = document.createElement("div");
    holder.id = iframeHolderId;
    holder.style.display = "none";
    const parent = target.parentElement;

    if (!parent) {
      return reject({
        error: true,
        reasons: [
          {
            code: "INVALID_CONFIGURATION",
            message: "Target element has no parent",
          },
        ],
      });
    }

    parent.appendChild(holder);

    const iframe = new IframeField(
      "payment-request",
      "#" + holder.id,
      assetBaseUrl + "field.html",
    );

    iframe.frame.allowPaymentRequest = true;

    instruments = instruments || defaultPaymentRequestInstruments();
    details = details || defaultPaymentRequestDetails();
    options = options || defaultPaymentRequestOptions();

    iframe.on("register", () => {
      postMessage.post(
        {
          data: {
            details,
            instruments,
            options,
          },
          id: iframe.id,
          type: "ui:iframe-field:payment-request-start",
        },
        iframe.id,
      );
    });

    iframe.on("token-success", (data?: object) => {
      resolve(data);
    });
    iframe.on("token-error", (data?: object) => {
      reset();
      reject(data);
    });
    iframe.on("payment-request-closed", () => {
      reset();
      reject({
        error: true,
        reasons: [
          { code: "PAYMENT_UI_CLOSED", message: "Payment card closed" },
        ],
      });
    });
    iframe.on("error", (e?: object) => {
      reset();
      reject({
        error: true,
        reasons: [e],
      });
    });
  });
}

const reset = () => {
  const el = document.getElementById(iframeHolderId);
  if (!el) {
    return;
  }

  if (el.remove) {
    el.remove();
  } else if (el.parentNode && el.parentNode.removeChild) {
    el.parentNode.removeChild(el);
  }
};
